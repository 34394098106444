.yappaCardCanjeada {
  background-color: var(--ion-color-primary);
  color: white;
  height: 460px;
  width: 95%;
  border-radius: 40px;
}

.tituloHistorial {
  color: var(--ion-color-primary);
}

.imagenCard {
  width: 100%;
  height: 200px !important;
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.seccionDatos {
  margin-left: 10px;
  margin-right: 10px;
}

.btnComentar {
  text-align: -webkit-center;
}

@media only screen and (man-width: 599px) {}

@media only screen and (min-width: 600px) and (max-width: 1199px) {
  .gridCard {
    padding-left: 1% !important;
    padding-right: 1% !important;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
  }

  .yappaCardCanjeada {
    height: 440px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1728px) {
  .gridCard {
    padding-left: 10%;
    padding-right: 10%;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: auto;
  }
}

@media only screen and (min-width: 1730px) {
  .gridCard {
    padding-left: 10%;
    padding-right: 10%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: auto;
  }
}