.sectionNav{
    display: inline-flexbox;
}

.btnBack{
border-radius: 25px;
size: 15px;
background-color: var(--ion-color-primary);
width: var(--tamanio-btn);
height: var(--tamanio-btn);
margin-left: 5px;
color: var(--ion-color-primary-contrast);
}
ion-icon{
    font-size: 25px;
}

.colBtnBack{
    width: 25%;
}
.colLogoNav{
    width: 75%;
}