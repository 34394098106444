.yappasRelacionadasContainer{
    width: 250px;
}

.fondoCardRelacionada {
    background-color: var(--ion-color-primary);
    color: white;
    width: 95%;
    border-radius: 40px;
    margin-bottom: 30px;
    height: 350px;
}

.yappaRelacionada {
    text-align: -webkit-center;
}

.imgCardelacinada {
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    cursor: pointer;
}

.botonVer {
    text-align: -webkit-center;
}

.informacionRelacionada {
    text-align: left;
    display: flex;
    justify-content: space-between;
    height: 70px;
    
}

.nombreYappaRela {
    font-size: 1.6em;
    align-self: center;
}
.detallesRelacionada{
    align-self: center;
    padding-left: 5px;
}


@media only screen and (max-width: 599px) {
    .fondoCardRelacionada {
        height: 290px;
    }

    .imgCard {
        width: 50px;
    }
    .nombreYappaRela {
        font-size: 1em;
    }

}

@media only screen and (min-width: 600px) and (max-width: 1199px) {
    .imgCard {
        width: 100%;
        height: 50%;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1728px) {
    .fondoCardRelacionada {
        height: 300px ;
        width: 95%;

    }

    .imgCard {
        width: 100%;

    }

    .nombreYappaRela {
        font-size: 1.6em;
     }
    .imgCardelacinada{
        cursor: pointer;
    }


}

@media only screen and (min-width: 1730px) {
    .nombreYappaRela h2 {
        font-size: 1em ;
     }
}