.btnVerYappainfo {
    display: flex;
    border-radius: 40px;
    padding-left: 25px !important;
    margin-top: 90px;
    /* width: 60% ; */
    height: 30px !important;
    justify-content: center;
    align-items: center !important;
    font-size: 1.5em;
    color: var(--ion-color-primary);
}



.textoVerYappa{
    font-size: 1em !important;
    color: var(--ion-color-primary);
    align-self: center;
}

.linkBtnVer {
    display: flex;
    text-decoration: none;
    justify-content: center;
    
    
}


@media only screen and (max-width: 600px) {
    .imgBtnveryappa {
        width: 30px !important;
    }

    .btnVerYappainfo {
        margin-top: 65px;
    }
    
}

@media only screen and (max-width: 650px) {
    .btnVerYappainfo {
        margin-top: 65px;
    }
    
}

@media only screen and (min-width: 600px) and (max-width:1199px) {
    .imgBtnveryappa {
        width: 30px !important;
        height: 30px;
    }
}

@media only screen and (min-width: 1200px) and (max-width:1728px) {
    .imgBtnveryappa {
        width: 40px !important;
        height: 40px;
    }
}
@media only screen and (min-width: 1730px) {
    .imgBtnveryappa{
        width: 40px !important;
    }
}