.imgBtnCanjearYappa {
    width: 40px !important ; 
}
.btnQuieroYappa h3{
    font-size: 1em;
    
}

.btnQuieroYappa {
    display: inline-flex;
    border-radius: 20px;
    
    height: 40px;
    color: red;
    background-color: white;
    align-items: center;   
    justify-content: center;
    margin-bottom: 5%;
    margin-top: 5%;
    justify-self: center;
}

@media only screen and (min-width: 600px) {
    
    .btnQuieroYappa {
        
        width: 80% !important;
        height: 50px;
   
    }
    .imgBtnCanjearYappa {
        width: 40px !important ; 
    }
    .tituloCard{
        color: var(--ion-color-primary-contrast);
        margin-bottom: 15px;
        font-size: 1.7em;
    
    }

}
@media only screen and (max-width: 600px) {
    .btnQuieroYappa {
        display: inline-flex;
      
        width: 100%;
        height: 70px;
        margin-bottom: 25px;
        margin-top: 15%;
      
        padding: 8px;
    }
    
    .btnQuieroYappa h3{
        font-size: 1.3em;
        
    }
    
    .imgBtn {
        width: 35px;
    }
    
    .fondoCard{
        background-color: var(--ion-color-primary);
        border-radius: 25px;
        color: var(--ion-color-primary-contrast);
        
    }
    .tituloCard{
        color: var(--ion-color-primary-contrast);
        margin-bottom: 15px;
        font-size: 1.7em;
    
    }
    
    .fondoCard p{
        margin-top: 10px;
        margin-bottom: 5px;
    }
    .btnconfirmar{
        padding-top: 25px;
        justify-content: center;
        display: flex;
    }
    .btnActionConfirmar{
        background-color: var(--ion-color-tertiary);
        color: var(--ion-color-primary-contrast);
        width: 100%;
        font-weight: bolder;
     font-size: 1.1em;
        padding-left: 25px;
        padding-right: 25px;
        height: 25px;
        border-radius: 25px;
    }
    .enlace{
        padding-top: 15px;
    }
    .enlace a{
        color: var(--ion-color-primary-contrast);
    }
    
    .imagenCard{
        margin: 0px;
        padding: 0px;
        border-radius: 25px;
    }
    
}