.tituloandbtnyappa {
    display: flex;
    justify-content: space-between !important;
    height: 120px;
  }
  
  .botonesLike {
    align-self: center;
  }
  
  h2.tituloCategoria {
    font-size: 2em;
  }
  
  .seccionContenido {
    color: white;
  }
  
  .textosDetalles {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
  }
  
  .nombreEmpresa {
    color: white;
    margin: -10px 0;
  }
  
  .textosDetalles2 {
    margin-top: -20px;
    display: flex;
    justify-content: space-between;
  }
  
  .fondoYappas {
    background-color: var(--ion-color-primary);
    border-radius: 40px;
    padding-bottom: 10px;
    width: auto;
    color: white;
  }
  
  .contenCard {
    background-color: var(--ion-color-primary);
    border-radius: 25px;
    cursor: pointer;
    justify-content: space-between;
    margin-bottom: 5%;
  }
  
  .contenGrid {
    padding-left: 5%;
    padding-right: 5%;
  }
  
  .infoFecha {
    width: 50% !important;
  }
  
  .fechaTextoYC {
    font-size: 1em;
  }
  
  .cantidadYappas {
    width: 40%;
    text-align: center;
  }
  
  .cantidadYappas h4 {
    font-size: 2.5em;
  }
  
  .colBtnCanjear {
    text-align: -webkit-center;
  }
  
  @media screen and (min-width: 600px) and (max-width: 1199px) {
    .fondoYappas {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto;
      margin: 0 25px;
    }
  
    h2.tituloCategoria {
      grid-column: 1/-1;
      padding: 25px 10px;
      color: white !important;
    }
  
    .contenCard {
      width: 90%;
      height: 530px;
      margin-bottom: 10px;
      box-shadow: 0px 1px 15px 10px rgba(0, 0, 0, 0.2);
      border-color: red;
      border-style: groove;
    }
  
    .seccionContenido {
      height: 220px;
    }
  
    .colBtnCanjear {
      margin-top: 25px;
    }
  }
  
  @media screen and (min-width: 1729px) {
    .fondoYappas {
      margin: 0 30px;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto;
    }
  
    h2.tituloCategoria {
      padding: 25px 10px;
      color: white !important;
      margin-bottom: 45px;
      margin-top: 25px;
      grid-column: 1/-1;
    }
  
    .contenCard {
      width: 85%;
      height: 575px;
      margin: 0 10% 5% 7%;
      cursor: pointer;
      box-shadow: 0px 1px 15px 10px rgba(0, 0, 0, 0.2);
      border-color: red;
      border-style: groove;
    }
  }
  
  @media screen and (min-width: 1200px) and (max-width: 1728px) {
    .fondoYappas {
      margin: 0 30px;
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: auto;
    }
  
    h2.tituloCategoria {
      padding: 25px 10px;
      color: white !important;
      margin-bottom: 45px;
      margin-top: 25px;
      grid-column: 1/-1;
    }
  
    .contenCard {
      background-color: var(--ion-color-primary);
      border-radius: 25px;
      width: 85%;
      height: 570px;
      margin-bottom: 5%;
      margin-left: 12%;
      cursor: pointer;
      box-shadow: 0px 1px 15px 10px rgba(0, 0, 0, 0.2);
      border-color: red;
      border-style: groove;
    }
  
    .seccionContenido {
      height: 220px !important;
    }
  
    .tituloandbtn {
      display: flex;
      justify-content: space-between !important;
      height: 100px !important;
    }
  
    h2.titulo,
    .colTextos {
      color: var(--ion-color-primary-contrast);
    }
  
    .colBtnLikeShare {
      white-space: nowrap;
    }
  
    .textosDetalles2 {
      margin-top: -20px;
      display: flex;
      justify-content: space-between;
      height: 180px !important;
    }
  
    .colBtnCanjear {
      margin-top: 25px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .yappaCaliente {
      color: var(--ion-text-color);
      border-radius: 25px;
      color: var(--ion-color-text);
      background: var(--ion-color-primary);
    }
  
    .contenCard {
      margin: 0px 1px 10px 1px;
      background-color: var(--ion-color-primary);
      border-radius: 25px;
      height: 575px;
    }
  
    img {
      border-radius: 40px;
    }
  
    .contenGrid {
      color: white;
    }
  
    .fondoYappas {
      background-color: var(--ion-color-primary);
      border-radius: 20px;
      padding-bottom: 10px;
      height: 575px;
    }
  
    h2.tituloCategoria {
      font-family: var(--ion-font-family);
      padding-left: 10px;
      color: white !important;
      margin-bottom: 45px;
      margin-top: 25px;
      padding-top: 25px;
    }
  
    h2.titulo {
      font-family: var(--ion-font-family);
      width: auto;
      font-size: 5.5vw;
      color: var(--ion-color-primary-contrast);
    }
  
    .colBtnCanjear {
      justify-content: center !important;
    }
  
    .colTextos {
      font-size: 1em;
      padding-left: 10px;
      font-family: var(--ion-font-family-text);
    }
  
    .colTextos b {
      font-size: 1.1em;
    }
  
    .yappaCaliente b #textoInformativo {
      font-size: 1.2em;
      margin-top: 10px;
      padding-left: 10px;
    }
  
    .yappaCaliente b {
      font-size: 1.3em;
      border-color: var(--ion-color-primary);
      font-weight: 700;
    }
  
    .tituloYappaRow {
      display: flex;
      align-items: center;
    }
  
    .tituloYappaCol {
      align-items: center;
      width: auto;
    }
  
    .rowBotonQuieroYappa {
      align-self: center;
    }
  
    .imgYappa {
      border-radius: 20px;
      width: 100%;
      height: 200px;
    }
  }
  