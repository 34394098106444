.yappaCard {
  background-color: var(--ion-color-primary);
  border-radius: 40px;
  height: 600px;
  color: white;
}

.imgCard {
  width: 100%;
  height: 200px;
}

.contenidoYappa {
  padding-left: 10px;
  padding-right: 10px;
  height: 240px;
}

.headerYappa {
  display: flex;
  justify-content: space-between;
  height: 120px;
}
.nombreCateogira {
  margin-bottom: 0px;
}
.nombreNegocio {
  margin: 0px;
}
.nombreYappa {
  margin: 0px;
}
.botonesLikeShare {
  align-self: center;
}
.detallesYappa {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
}
.detalles2Yappa {
  display: flex;
  justify-content: space-between;
  margin-top: -20px;
}
.detalleFecha {
  width: 50%;
}
.fechaTexto {
  font-size: 1.4em;
}
.detalleCantidadYappas {
  width: 40%;
  text-align: center;
}
.cantidaYappas {
  font-size: 2.5em;
}

.seccionBotonVerYappa {
  text-align: -webkit-center;
}

@media only screen and (max-width: 599px) {
}
@media only screen and (max-width: 600px) {
  .contenCard {
    height: 480px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1199px) {
}

@media only screen and (min-width: 1200px) and (max-width: 1728px) {
}

@media only screen and (min-width: 1730px) {
}
