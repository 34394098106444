/* registro user swiper */
#app {
  height: 100%
}

html,
body {
  position: relative;
  height: 100%;
}

body {
  background: #eee;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

body {
  background: #fff;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

html,
body {
  position: relative;
  height: 100%;
}

#app {
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  border-radius: 18px;
  font-size: 22px;
  font-weight: bold;
  color: #fff;
}




/* inicia codigo css para las slide */

.contentPage {
  text-align: center;
}

.mySwiper {
  margin-top: 10px;
}

.SlideReg {
  border-bottom-left-radius: 50px;
  border-bottom-right-radius: 50px;
}

.slidereg1 {
  background-color: var(--ion-color-primary);
}

.slidereg2 {
  background-color: var(--ion-color-secondary);
}

.slideInfo {
  display: grid;
  grid-template-columns: auto;
  padding: 3vw;
  text-align: left;
}

.sectionLogoReg {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 8vh;
}

.logoYappaSlide {
  width: 200px !important;
  border-radius: 0px;
}

.sectionBienvenida {
  margin-bottom: 20px;
}

.btnAceptoReg {
  width: 80vw;
  color: red;
  background-color: white;
  padding: 5px;
  border-radius: 30px;
  font-size: 15px;
  font-weight: 200 !important;
}

.divMailBTN {
  padding-bottom: 50px;
  padding-top: 10px;

}

.inputMailReg {
  background-color: white;
  border-radius: 5px;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  color: black;
  font-weight: lighter;
  padding-left: 5px;
}

.textRand {
  text-align: center;
}

.btnContinuarReg,
.btnRegGoogle {
  width: 80vw;
  padding: 5px;
  border-radius: 30px;
  font-size: 15px;
}

.divContBTN {
  text-align: center;
}

.textRell {
  margin-top: 20px;
  text-align: center;
}

.divNombreReg {
  margin-top: 20px;
  margin-bottom: 20px;
}

.chipGustos {
  color: white;
  border-color: white;
}

.seccionBotonesReg {
  text-align: center;
  margin-top: 40px;
}

.btnCancelar,
.btnRegistrar {
  width: 40vw;
  border-radius: 30px;
  font-size: 15px;
  font-weight: 500;
  height: 40px;
  margin: 5px;
}

.btnRegistrar {
  background-color: white;
  color: var(--ion-color-primary);

}

.btnCancelar {
  background-color: var(--ion-color-primary);
  color: white;
}

.swiper-cards .swiper-slide {
  height: 95vh !important;
  width: 90vw !important;
  align-self: flex-start;
}

@media only screen and (min-width: 800px) {

  .swiper-slide.swiper-slide-active.SlideReg.slidereg1,
  .swiper-slide.swiper-slide-active.SlideReg.slidereg2 {
    text-align: -webkit-center;
  }

  .SlideReg {
    text-align: center;
    align-items: center;
  }

  .slideInfo {
    display: block;
    width: 40vw;
  }

  .btnAceptoReg,
  .btnContinuarReg,
  .btnRegGoogle {
    width: 30vw;
    margin-bottom: 15px;
  }

}