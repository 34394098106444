ion-menu::part(backdrop) {
    background-color: var(--ion-color-primary);
}

ion-menu::part(container) {
    border-radius: 40px 0px 0px 40px;

    /* box-shadow: 4px 0px 16px rgba(255, 0, 255, 0.18); */
}
.ICSesion h5{
font-weight: 900;
}
.textoBtnMenu{
    background-color: transparent;
    color: white;
    font-weight: 800;
}

.contenidoMenu {
    background-color: red;
}

.seccionOpciones {
    display: grid;
    grid-template-rows: repeat(3, 1ft);
    height: 100%;

    color: white;
    background-color: var(--ion-color-primary);
}

.InfoUsuario {
    padding-bottom: 25px;
    padding-top: 10px;
    padding-right: 25px;
    padding-left: 55px;
}

.seccionInfoUser {
    height: 100px;
    height: 150px;
}

.InfoUsuario h2 {

    font-size: 2.2em;
    text-align-last: center;
}

.StiloOpciones {
    height: 75%;
    padding-right: 25px;
    padding-left: 50px;
}

.StiloOpcionesInferior {
    align-self: self-end;
    margin-bottom: 25px;
    text-align: center;
}

.StiloOpcionesInferior button {
    font-size: 1.2em;
    width: 170px;
    height: 45px;
    border-radius: 40px;
    color: var(--ion-color-primary);
}

.TituloMenu {
    color: var(--ion-color-primary);
}

.opcionesMenu {
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid white;
}
.ToolBarNavMenu{
    text-align: center;
    background-color: white !important;
}
.logoMenuYappa{
    align-items: center;
    width: 100px;
    height: auto;
    border-radius: 0px !important;
}

