
.headerNavBarA{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cssBack{
    width: 10%;
}
.cssBottonMenu{
    width: 10%;
    color: red !important;
}

.button-native{
    height: 50px;
    background-color: red;
    width: 50px;
    color: white;
}

@media only screen and (max-width: 600px) {
    .fondo{
        background-color: var(--ion-color-primary);
    } 
    
}

@media only screen and (min-width: 600px ) {
    
}
