.informacionYappaDetalles {
  margin-top: 40px;
}

.seccionComentarios {
  background-color: var(--ion-color-primary);
  color: white;
  border-radius: 40px;
}

.alert-wrapper.sc-ion-alert-md {
  background-size: 400px;
  padding-top: 200px;
  max-width: 380px;
  height: 500px;

  background-color: transparent;
}

.MsgError {
  background-image: url("https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/484285f7-d818-4b04-9e5e-fce41c2ece00/public");
  background-repeat: no-repeat;
  background-position: center !important;
  background-size: 400px;
  width: 100%;
  height: 100%;

  justify-self: center;
  align-self: center;
}

.MsgTimer {
  background-image: url("https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/c26ec4b7-cb98-45f3-213e-f2598bf08e00/public");
  background-repeat: no-repeat;
  background-position: center !important;
  background-size: 400px;
  width: 100%;
  height: 100%;

  justify-self: center;
  align-self: center;

  /* background: transparent !important; */
  box-shadow: 0px 0px 0px transparent !important;
}

.MsgOk {
  background-image: url("https://imagedelivery.net/NUiIP66VpPz374h46kC-PA/c26ec4b7-cb98-45f3-213e-f2598bf08e00/public");
  background-repeat: no-repeat;
  background-position: center !important;
  background-size: 400px;
  width: 100%;
  height: 100%;

  justify-self: center;
  align-self: center;
}

.loading-wrapper.sc-ion-loading-md {
  background: transparent !important;
  -webkit-box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
  box-shadow: 0 0px 0px rgba(0, 0, 0, 0) !important;
}

.alert-button-inner.sc-ion-alert-md {
  justify-content: center !important;
}
.alert-title.sc-ion-alert-md {
  color: white;
  font-size: 20px;
  padding-left: 10px;
  font-weight: 200;
  text-align: center;
}
.alert-button-group.sc-ion-alert-md {
  justify-content: space-around;
  margin-top: 20px;
  margin-left: 10px;
}
.alert-button.sc-ion-alert-md {
  background-color: white;
  border-radius: 40px;
  width: 150px;
}

.vinculoA {
  text-decoration: none;
  display: flex;
  background-color: white !important;
}
.imagenCategoriaYappa {
  margin-left: 15px;
  margin-right: -15px;
  width: 80px;
}
.fondoPage {
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  border-radius: 40px;
}

.headerYappaInformacion {
  display: flex;
  justify-content: space-between;
  height: 80px;
}

.nombreCategoria {
  font-size: 1.3em !important;
}
.nombreNegocio {
  font-size: 1.5em !important;
}
.nombreYappa {
  font-size: 1.6em !important;
}

.botoneslikeshare {
  align-self: center;
}

.enlace a {
  color: var(--ion-color-primary-contrast);
}

.fondoPage h2 {
  font-size: 1.7em;
}

.cometarioYappa {
  background-color: var(--ion-color-primary);
}
.tituloSugerencias {
  color: var(--ion-color-primary);
  padding-left: 35px;
}
.sectionBtnCanjear {
  text-align: center;
}

@media only screen and (max-width: 599px) {
  .fondoPage p {
    margin-top: 10px;
    margin-bottom: 5px;
  }

  .imagenYappa {
    border-top-right-radius: 40px !important;
    border-top-left-radius: 40px !important;
  }

  .headerYappaInformacion {
    height: 150px;
  }
  .card-content-md h2 {
    font-size: 1.5em;
  }

  .sectionBtnCanjear {
    justify-content: center;
    display: flex;
  }

  h2 #tituloComentario {
    margin-left: 10px;
    margin-right: 10px;
    padding-left: 20px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1199px) {
  .fondoPage {
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    border-radius: 50px;
    width: auto !important;
  }

  .ColCardIMG {
    width: 100%;
  }

  .imagenYappa {
    margin: 0px !important;
    height: 400px !important;
    border-top-right-radius: 40px;
    border-top-left-radius: 40px;
  }

  .ColCardInformation {
    width: 100% !important;
  }

  .tituloBtnLikeShare {
    padding-bottom: 10%;
  }

  .card-content-md h2 {
    font-size: 2rem;
  }

  .sectionBtnCanjear {
    text-align: center;
  }

  .seccionComentarios {
    height: 250px;
  }

  .yappaRelacionada {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: auto;
  }

  .tituloSugerencias {
    color: var(--ion-color-primary);
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1728px) {
  .GridCard {
    display: flex;
  }
  .ColCardIMG {
    width: 60%;
  }
  .imagenYappa {
    height: 100%;
  }
  .ColCardInformation {
    width: 40%;
  }
  .headerYappaInformacion {
    margin-bottom: 25px;
  }

  .sectionBtnCanjear {
    text-align: center;
  }

  .seccionSugerencias {
    margin-left: 15px;
  }

  .tituloSugerencias {
    padding-left: 10px;
  }

  .informacionYappaDetalles p {
    margin-bottom: 5px;
  }
}

@media only screen and (min-width: 1730px) {
  .GridCard {
    display: flex;
  }
}
