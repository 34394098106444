.colorCard {
  color: aliceblue;
  background-color: var(--ion-color-primary);
  border-radius: 40px;
  height: 550px;
  width: 380px;
}

.imgCard {
  border-radius: 25px;
  width: 100%;
  height: 200px;
  cursor: pointer;
}

.tituloandbtnBusqueda {
  display: flex;
  justify-content: space-between !important;
  height: 110px !important;
}
.infoYappanego {
  text-align: left;
}
.tituloBusqueda {
  font-size: 1.8em !important;
}
.nombreEmpresa {
  font-size: 20px !important;
}
.nombreCategoria {
  font-size: 16px !important;
}
.tituloandbtn {
  height: 100% !important;
  margin-bottom: 15px;
}
.textosDetalles {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.infoFecha {
  font-size: 20px !important;
  width: 80% !important;
  text-align: left;
}

.textosDetallesBusqueda2 {
  display: flex;
  justify-content: space-between;
}

.detallefecha {
  justify-content: space-between;
}
.cantidadYappas {
  justify-content: space-between;
}

.btnVerYappa {
  justify-content: center;
  width: 90%;
  display: flex;
  margin-top: 20px;
  align-items: center;
  text-align: center;
  border-radius: 20px;
}
