ion-card-content.points {
  --border-radius: 20px;
  padding: 16px;
  display: grid;
  grid-template-columns: 40% 60%;
  grid-template-rows: 100%;
  text-align: center;
  align-items: center;
  
}

.imgContent{
  width: 100%;
}
.carContent{
  border-radius: 20px;
}

.left{
    grid-column: 1/2;
}

span.b{
  font-size: 8px;
}

ion-text.right{
    grid-column: -1/2;
}