ion-searchbar.search {
  --border-radius: 20px;
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
}


.resulNo {
  border-radius: 25px;
  text-align: center;
  background-color: var(--ion-color-primary);
  color: var(--ion-color-primary-contrast);
  padding-top: 5px;
  padding-bottom: 5px;
  margin-left: 15px;
  margin-right: 15px;
}

.searchbar-input-container.sc-ion-searchbar-md {
  display: flex;
  justify-content: center;
}

.seccionSwiper{
   text-align: -webkit-center;
}

@media only screen and (min-width: 1730px) {
  .searchbar-input-container.sc-ion-searchbar-md {
    width: 40%;
  }
  .seccionSwiper {
    margin-right: 5vw;
    margin-left: 5vw;
   
  }

  .resulNo {
    width: 40%;
    height: 50px;
    text-align: center;
    justify-content: center;
    align-content: center;
  }

}

@media only screen and (min-width: 1200px) and (max-width: 1728px) {
  .searchbar-input-container.sc-ion-searchbar-md {
    width: 50%;
  }
}

@media only screen and (min-width: 600px) and (max-width:1199px) {

  .searchbar-input-container.sc-ion-searchbar-md {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 70%;
  }

  .seccionSwiper {
    margin-right: 1%;
    margin-left: 1%;
  }

}

@media only screen and (max-width: 599px) {
  ion-searchbar.search {
    width: 98%;
  }


}