.tituloSeccion {
    font-size: 2em;
}
.cardCategory {
    cursor: pointer;
    background: transparent;
}

.img_category_container{
    text-align: center;
    width: 120px;
}

@media only screen and (max-width: 600px) {

    .img_category_container{
        width: 80px;
    }

    .formatSeccion {
        margin-bottom: 40px;
        margin-top: 40px;
        align-items: center;
    }

    .tituloSeccion {
        

        margin-left: 10px;
        color: var(--ion-color-primary);
        font-family: var(--ion-font-family);
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .titleCategory {
        font-size: 10px;
    }

    .cardCategory {
        width: 50px;
        height: 140px;
        border-radius: 15px;
        margin-right: 0px;
        padding-bottom: 15px;
        border-style: none;
        align-items: center;
        box-shadow: none;
    }

    #imgCat {
        width: 65px;
        cursor: pointer;
    }

    .swiper .swiper-slide img #imgCat {
        font-size: 13px;
    }

    .swiper .swiper-slide b {
        font-size: 12px;
        font-weight: 500;
    }

    .categoriaTexto {
        text-transform: capitalize;
        font-size: small;
    }


}

@media only screen and (min-width: 600px) {
    #imgCat {
        width: 120px;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .tituloSeccion {
        padding-left: 45px;
       
        color: var(--ion-color-primary);
        margin-top: 15px !important;
        margin-bottom: 15px !important;
    }

    .cardCategory {
        margin-top: 25px;
        margin-bottom: 25px;
        box-shadow: none;
    }

    .categoriaTexto {
        text-transform: capitalize;
        font-size: small;
    }

    .sectionSwiper {
        margin-bottom: 15px;
        
    }

    .sectionBanner {
        margin-bottom: 50px;
    }

    .cardCategory {
        margin-bottom: 30px;
       
    }
    .formatSeccion {
        margin-bottom: 40px;
     }
}