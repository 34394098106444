
.btns{
    display: grid;
    justify-content: flex-end;
    z-index: 100;
    gap: 10px;

}
.btnLike{
    background-color: white !important;
    color: var(--ion-color-primary) !important;
    border-radius: 25px !important;
    width: 40px;
    height: 40px; 
    z-index: 100;
}
.btnShare{
    background-color: white !important;
    color: #25d366 !important;
    border-radius:25px;
    width: 40px;
    height: 40px;
    z-index: 100;
}

.buttonLike{
    border-radius: 15px;
    color: var(--ion-color-text) !important;
    padding: 2px 1px 2px 1px;
    margin: 0px;
}
.buttonShare{
    border-radius: 15px;
    color: var(--ion-color-text) !important;
    padding: 2px 1px 2px 1px;
    margin: 0px;
    
}