.gridCard {
  justify-items: center;
}

.tituloCategoria {
  color: var(--ion-color-primary);
}


.bannerCategoria {
  width: 100%;
}

.imagenCategoriaDestacada {
  width: 100%;
  border-radius: 0px;
}

.navInformation {
  display: flex;
  margin-left: 50px;
  place-items: center;
}

.imagenCategoria {
  width: 70px;
  margin-right: -15px;
}

@media only screen and (max-width: 599px) {
  .navInformation {
    margin-left: 10px;
  }
  .imagenCategoria{
    width: 90px;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1199px) {
  .gridCard {
    padding-left: 10%;
    padding-right: 10%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1728px) {
  .gridCard {
    padding-left: 10%;
    padding-right: 10%;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    grid-template-rows: auto;
  }

}

@media only screen and (min-width: 1730px) {
  .gridCard {
    padding-left: 10%;
    padding-right: 10%;
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;
    grid-template-rows: auto;
  }

}