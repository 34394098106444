.cardNegocio {
    border-radius: 20px;
}

.imgCard{
    width: 100%;
}

.titelCenter{
    text-align: center;
}

.btnsFooter{
    align-items: center;
}

.btnLike{
    background-color: var(--ion-color-secondary);
    color: var(--ion-text-color);
    border-radius: 25px; 
}
.btnShare{
    background-color: var(--ion-color-tertiary);
    color: var(--ion-text-color);
    border-radius:25px;
}