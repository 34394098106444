@media only screen and (max-width: 600px) {
    .imgPau {
        border-radius: 15px;
        background-color: var(--ion-color-primary);
    }

    .swiper-pagination {
        height: 25px;
    }

    .sectionBanner {
        margin-bottom: 30px;
    }
}

@media only screen and (min-width: 600px) {
    .swiper .swiper-slide {
        display: inline;
    }

    .swiper .swiper-slide img {
        width: 100%;
    }

    .imgPau {
        /* border-top-right-radius: 40px;
       border-top-left-radius: 40px; */
        width: 100%;

    }

    .sectionBanner {
        margin-bottom: 5px;
    }
}